// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".index__fixed-bg___3I8Av {\n    z-index: -100\n}\n.index__fixed-bg___3I8Av > .index__top___2rOCY {\n        position: fixed;\n        top: 0;\n        left: 0;\n        width: 100vw;\n        height: 50vh;\n        background-color: #e6d2be;\n    }\n.index__fixed-bg___3I8Av > .index__bottom___kD0mp {\n        position: fixed;\n        bottom: -30vh;\n        left: 0;\n        width: 100vw;\n        height: 80vh;\n        background-color: #27806E;\n    }\n", "",{"version":3,"sources":["webpack://./src/routes/index.css"],"names":[],"mappings":"AAAA;IACI;AAmBJ;AAjBI;QACI,eAAe;QACf,MAAM;QACN,OAAO;QACP,YAAY;QACZ,YAAY;QACZ,yBAAyB;IAC7B;AAEA;QACI,eAAe;QACf,aAAa;QACb,OAAO;QACP,YAAY;QACZ,YAAY;QACZ,yBAAgC;IACpC","sourcesContent":[".fixed-bg {\n    z-index: -100;\n\n    & > .top {\n        position: fixed;\n        top: 0;\n        left: 0;\n        width: 100vw;\n        height: 50vh;\n        background-color: #e6d2be;\n    }\n\n    & > .bottom {\n        position: fixed;\n        bottom: -30vh;\n        left: 0;\n        width: 100vw;\n        height: 80vh;\n        background-color: var(--primary);\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"fixed-bg": "index__fixed-bg___3I8Av",
	"fixedBg": "index__fixed-bg___3I8Av",
	"top": "index__top___2rOCY",
	"bottom": "index__bottom___kD0mp"
};
export default ___CSS_LOADER_EXPORT___;
