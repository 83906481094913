import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import { reducer as globalReducer } from 'store/global';

import type { History } from 'history';
import type { State as GlobalState } from 'store/global';

export interface State {
    global: GlobalState;
}

const createRootReducer = (history: History) =>
    combineReducers({
        router: connectRouter(history),
        global: globalReducer,
    });

export default createRootReducer;
