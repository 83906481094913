import { configureStore } from '@reduxjs/toolkit';
import { routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';

import createRootReducer from './reducer';

export const history = createBrowserHistory();

const reducer = createRootReducer(history);

const store = configureStore({
    reducer,
    devTools: process.env.NODE_ENV !== 'production',
    middleware: (getDefaultMiddleware) => {
        if (process.env.NODE_ENV !== 'production') {
            // eslint-disable-next-line global-require, @typescript-eslint/no-var-requires
            const { createLogger } = require('redux-logger');

            return getDefaultMiddleware().concat(createLogger());
        }

        getDefaultMiddleware().concat(routerMiddleware(history));

        return getDefaultMiddleware();
    },
});

export default store;

export type { State } from './reducer';
