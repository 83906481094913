import { Suspense } from 'react';
import { renderRoutes } from 'react-router-config';

import type { RouteConfigComponentProps } from 'react-router-config';

import styles from './index.css';

const App = ({ route }: RouteConfigComponentProps) => (
    <Suspense fallback={<></>}>
        {/* child routes won't render without this */}
        <div className={styles.fixedBg}>
            <div className={styles.top} />
            <div className={styles.bottom} />
        </div>
        {renderRoutes(route && route.routes)}
    </Suspense>
);

export default App;
