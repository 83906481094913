/* eslint-disable no-param-reassign */
import { createReducer } from '@reduxjs/toolkit';

// use HYDRATE when server action is used
// https://www.youtube.com/watch?v=bpbLq6NxIm8&ab_channel=MayankSrivastava
// import { HYDRATE } from 'next-redux-wrapper';

import { actionA, actionB, actionC } from './actions';

export interface State {
    value: number;
}

const initialState: State = {
    value: 0,
};

export const reducer = createReducer(initialState, (builder) => {
    builder
        .addCase(actionA, (state, action) => {
            state.value += action.payload;
        })
        .addCase(actionB, (state, action) => {
            return {
                ...state,
                value: state.value + action.payload,
            };
        })
        .addCase(actionC.fulfilled, (state, action) => {
            state.value += action.payload;
        });
});
