import { lazy } from 'react';

// import notFoundRoutes from 'routes/NotFound';

export default [
    {
        path: ['/'],
        exact: true,
        component: lazy(() => import(/* webpackChunkName: 'HomeLayout' */ './layout')),
    },
];
